import { useWebConfig } from 'features/configuration/queries';
import { StyledSuggestionsBar, SuggestionsBarLink } from './SuggestionsBar.styled';

type SuggestionsBarProps = { className?: string };

const SuggestionsBar = ({ className }: SuggestionsBarProps) => {
  const { data: webConfig } = useWebConfig();

  return (
    <StyledSuggestionsBar className={className}>
      {webConfig?.suggestions?.map(
        (suggestion) =>
          suggestion?.url && (
            <SuggestionsBarLink key={`${suggestion.displayText}`} prefetch={false} url={suggestion?.url}>
              {suggestion?.displayText}
            </SuggestionsBarLink>
          ),
      )}
    </StyledSuggestionsBar>
  );
};

export default SuggestionsBar;
