import styled from 'styled-components';
import { fontSize, spacer, specific, zIndex } from 'theme';
import Container from 'ui/src/Container/Container';
import Link from '../../../Link/Link';

export const StyledSuggestionsBar = styled(Container)`
  background-color: ${specific('header', 'suggestions-bar-background-color')};
  align-items: center;
  position: relative;
  z-index: ${zIndex('base')};
  min-height: 45px;
  padding: 0px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: calc((100vw - 100%) / 2);
    top: 0;
    height: 100%;
    background-color: ${specific('header', 'suggestions-bar-background-color')};
  }

  &::before {
    right: 100%;
  }

  &::after {
    left: 100%;
  }

  &:empty {
    // Give a height when empty to reduce visual jump in effect
    height: 45px;
  }
`;

export const SuggestionsBarLink = styled(Link)`
  text-decoration: none;
  white-space: nowrap;
  font-size: ${fontSize(88)};
  padding: ${spacer(75)} ${spacer(75)};

  &&& {
    &,
    &:link,
    &:visited {
      color: ${specific('header', 'suggestions-bar-color')} !important;
      font-size: ${fontSize(88)};
      font-weight: bold;
    }
  }
`;
