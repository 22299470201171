import styled, { css } from 'styled-components';
import { breakpointUp, spacer, specific, variable, zIndex } from 'theme';
import Container from 'ui/src/Container/Container';
import { BREAKPOINTS } from '../../../constants/breakpoints';
import Icon from '../../Icon/Icon';
import Link from '../../Link/Link';
import CartButton from './Cart/CartButton/CartButton';
import CategoryButton from './Category/CategoryButton/CategoryButton';
import StoreButton from './StoreButton/StoreButton';

const MAIN_BAR_TOP_HEIGHT = '55px';
const MAIN_BAR_TOP_HEIGHT_LARGE = '72px';
const MAIN_BAR_BUTTON_STYLING = css`
  display: flex;
  align-items: center;

  && {
    color: ${specific('mainbar', 'text-color')};
    fill: ${specific('mainbar', 'text-color')};
  }
`;

export interface StyledMainBarContainerProps {
  minimal: boolean;
}

export const StyledMainBarContainer = styled.div<StyledMainBarContainerProps>`
  position: relative;
  z-index: ${zIndex('header')};

  ${({ minimal }) => minimal && variable('border-bottom')}
`;

export const StyledMainBar = styled(Container)`
  position: relative;
  z-index: 200;
  background-color: ${specific('mainbar', 'background-color')};
  transition: ${variable('transition')};

  &::before {
    right: 100%;
  }

  &::after {
    left: 100%;
  }

  &::before,
  &::after {
    background-color: ${specific('mainbar', 'background-color')};
    content: '';
    position: absolute;
    width: calc((100vw - 100%) / 2);
    top: 0;
    height: 100%;
  }
`;

export const StyledMainBarTopRow = styled.div`
  padding-top: ${spacer(75)};
  padding-bottom: ${spacer(75)};
  background-color: ${specific('mainbar', 'background-color')};
  min-height: ${MAIN_BAR_TOP_HEIGHT};

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      min-height: ${MAIN_BAR_TOP_HEIGHT_LARGE};
    `,
  )}
`;

export const StyledMainBarActions = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  gap: ${spacer(150)};
  justify-content: flex-end;
  position: relative;

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      order: 4;
      gap: ${spacer(200)};
    `,
  )}
`;

export const StyledMainBarStoreButton = styled(StoreButton)`
  ${MAIN_BAR_BUTTON_STYLING}
`;

export const StyledMainBarButtonCart = styled(CartButton)`
  ${MAIN_BAR_BUTTON_STYLING}
`;

export const StyledMainBarHamburger = styled(Icon)`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    color: ${specific('mainbar', 'hamburger-color')};
    fill: ${specific('mainbar', 'hamburger-color')};
  }

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      display: none;
    `,
  )}
`;

export const StyledMainBarLogoLink = styled(Link)`
  width: 140px;
  height: 100%;
  min-height: ${spacer(250)};
  position: relative;
  display: block;

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      width: 175px;
    `,
  )}
`;

export const StyledMainBarCategoryButtonWrapper = styled.div`
  display: none;

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      order: 2;
    `,
  )}
`;

export const StyledMainBarCategoryButton = styled(CategoryButton)`
  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      && {
        color: ${specific('mainbar', 'text-color')};
      }
      text-decoration: none;
      display: flex;
      gap: ${spacer(25)};
    `,
  )}
`;
