import useAuthResolver from 'hooks/useAuthResolver';
import { useIntl } from 'react-intl';
import { useAuthActions } from 'store/auth';
import { DropdownMenu, DropdownMenuContent, DropdownMenuSeparator, DropdownMenuTrigger, Icon, Text } from 'ui';
import { cn } from 'utils/cn';
import { MY_ACCOUNT_HELP_ITEMS, MY_ACCOUNT_MENU_ITEMS } from '../../../../../constants/myAccount';
import useCustomSession from '../../../../../hooks/useCustomSession';
import Flex from '../../../../Flex/Flex';
import Link from '../../../../Link/Link';

const ACCOUNT_ID = 'account-button';

type Props = {
  show?: boolean;
  toggleAccountDropDown: () => void;
};

const AccountMenuDropdownItem = ({
  className,
  textId,
  withIcon = true,
}: {
  className?: string;
  textId: string;
  withIcon?: boolean;
}) => {
  const { formatMessage } = useIntl();

  return (
    <div
      className={cn(
        '[&_*]:text-menu-foreground-default [&_*]:hover:text-menu-foreground-hover flex items-center justify-between gap-4 font-bold',
        className,
      )}
    >
      <Text>{formatMessage({ id: textId })}</Text>
      {withIcon && <Icon className="size-5" icon={{ name: 'angle-right', styling: 'fal' }} />}
    </div>
  );
};

const AccountMenuDropdown = ({ show, toggleAccountDropDown }: Props) => {
  const { formatMessage } = useIntl();
  const { logout } = useCustomSession();
  const { anonymous, user } = useAuthResolver();
  const { setAuthFlyoverVisible } = useAuthActions();

  const welcomeText = user
    ? formatMessage(
        {
          id: 'header_main_nav_account_welcome',
        },
        { firstName: user.firstName },
      )
    : formatMessage({
        id: 'header_main_nav_account_my_account',
      });
  const handleClick = () => {
    if (!anonymous) {
      toggleAccountDropDown();
    } else {
      setAuthFlyoverVisible(true);
    }
  };

  return (
    <DropdownMenu open={show} onOpenChange={handleClick}>
      <DropdownMenuTrigger
        className="flex items-center gap-1"
        aria-label={formatMessage({ id: 'my_account_button_label' })}
        id={ACCOUNT_ID}
      >
        <Icon className="fill-mainBar-foreground size-7" icon={{ name: 'user', styling: 'custom' }} />
        <Text className="text-mainBar-foreground hidden font-bold md:block">{welcomeText}</Text>
      </DropdownMenuTrigger>
      <DropdownMenuContent sideOffset={20}>
        <Flex flexDirection="column" gap={100}>
          {/* Menu Items */}
          {MY_ACCOUNT_MENU_ITEMS.map(
            (item) =>
              item?.show && (
                <div key={item.textId}>
                  {item.isLink && (
                    <Link url={item.url}>
                      <AccountMenuDropdownItem className="" textId={item.textId} />
                    </Link>
                  )}
                </div>
              ),
          )}

          {/* Sign out  */}
          <div onClick={() => logout({ callback: toggleAccountDropDown })} role="button" tabIndex={0}>
            <AccountMenuDropdownItem textId="my_account_menu_logout_tab" />
          </div>
        </Flex>

        {/* Extra Links */}
        {MY_ACCOUNT_HELP_ITEMS.length > 0 && (
          <>
            <DropdownMenuSeparator className="my-6" />

            <Flex flexDirection="column" gap={50}>
              {MY_ACCOUNT_HELP_ITEMS.map(
                (item) =>
                  !!item?.show && (
                    <Link key={item.textId} url={item.url}>
                      <AccountMenuDropdownItem className="font-normal" textId={item.textId} withIcon={false} />
                    </Link>
                  ),
              )}
            </Flex>
          </>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default AccountMenuDropdown;
