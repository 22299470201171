import { CMSPageData, RobotTag } from 'types/CMS';

export const getCMSPageLinks = (cmsPage: CMSPageData, slotId: string) =>
  cmsPage?.slots?.find((slot) => slot.slotId === slotId)?.components?.[0]?.linkComponents || [];

export const getLocalizedQualifier = (slug: string, locale: string) => `${slug}-${locale}`;

export const mapRobotTag = (robotTag?: RobotTag) => {
  switch (robotTag) {
    case 'NOINDEX_FOLLOW':
      return 'noindex, follow';
    case 'INDEX_NOFOLLOW':
      return 'index, nofollow';
    case 'NOINDEX_NOFOLLOW':
      return 'noindex, nofollow';
    default:
      return 'index, follow';
  }
};
